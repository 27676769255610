import { queryServices } from '@wix/ambassador-bookings-services-v2-service/build/cjs/http.impl';
import {
  QueryServicesRequest,
  LocationType,
  SortOrder,
  Service,
} from '@wix/ambassador-bookings-services-v2-service/types';
import { ReservedLocationIds } from '@wix/bookings-uou-types';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { ServiceListSettings } from '../../legacy/appSettings/appSettings';
import { buildQueryServicesFilter } from '../utils/filters/buildQueryServicesFilter';
import { ServicesCatalogServer } from '@wix/ambassador-services-catalog-server/http';
import {
  getInstance,
  getServerBaseUrl,
} from '@wix/bookings-catalog-calendar-viewer-utils';
import { NotificationsServer } from '@wix/ambassador-notifications-server/http';
import { BOOKINGS_FES_BASE_DOMAIN } from '../consts';

type QueryServicesRequestQuery = QueryServicesRequest['query'];
type QueryServicesRequestFilters = QueryServicesRequestQuery['filter'];
type QueryServicesRequestPaging = QueryServicesRequestQuery['paging'];

export type QueryServicesFilter = {
  staffMemberIds?: string[];
  categoryIds?: string[];
  serviceIds?: string[];
  locationIds?: (string | typeof ReservedLocationIds.OTHER_LOCATIONS)[];
  limit?: number;
};

export const CATALOG_SERVER_URL = '_api/services-catalog';
export const NOTIFICATIONS_SERVER_URL = '_api/notifications-server';
export const XSRF_HEADER_NAME = 'X-XSRF-TOKEN';
export const REVISION_HEADER_NAME = 'x-wix-site-revision';

export class BookingsAPI {
  private static cache = new Map();
  private flowAPI: ControllerFlowAPI;
  private appSettings: ServiceListSettings;
  private shouldWorkWithAppSettings: boolean;
  private catalogServer: ReturnType<typeof ServicesCatalogServer>;
  private notificationsServer: ReturnType<typeof NotificationsServer>;

  constructor({
    appSettings,
    flowAPI,
    shouldWorkWithAppSettings,
  }: {
    flowAPI: ControllerFlowAPI;
    appSettings: ServiceListSettings;
    shouldWorkWithAppSettings: boolean;
  }) {
    const serverBaseUrl = getServerBaseUrl({
      wixCodeApi: flowAPI.controllerConfig.wixCodeApi,
      appParams: flowAPI.controllerConfig.appParams,
    });

    this.flowAPI = flowAPI;
    this.appSettings = appSettings;
    this.shouldWorkWithAppSettings = shouldWorkWithAppSettings;
    this.catalogServer = ServicesCatalogServer(
      `${serverBaseUrl}${CATALOG_SERVER_URL}`,
    );
    this.notificationsServer = NotificationsServer(
      `${serverBaseUrl}${NOTIFICATIONS_SERVER_URL}`,
    );
  }

  static clearCache() {
    BookingsAPI.cache.clear();
  }

  private get authorization() {
    return getInstance({
      wixCodeApi: this.flowAPI.controllerConfig.wixCodeApi,
      appParams: this.flowAPI.controllerConfig.appParams,
    });
  }

  private async withCache<T>(
    key: string,
    request: () => Promise<T> | T,
  ): Promise<T> {
    const cachedResult = BookingsAPI.cache.get(key);

    if (cachedResult) {
      return cachedResult;
    }

    const result = await request();
    BookingsAPI.cache.set(key, result);

    return result;
  }

  queryServices() {
    const filters = buildQueryServicesFilter({
      flowAPI: this.flowAPI,
      shouldWorkWithAppSettings: this.shouldWorkWithAppSettings,
      appSettings: this.appSettings,
    });
    const {
      categoryIds,
      locationIds,
      serviceIds,
      staffMemberIds,
      limit = 500,
    } = filters;
    const sort = [
      {
        fieldName: 'category.sortOrder',
        order: SortOrder.ASC,
      },
      {
        fieldName: 'sortOrder',
        order: SortOrder.ASC,
      },
    ];
    const paging: QueryServicesRequestPaging = { limit };
    let filter: QueryServicesRequestFilters = {};
    const hiddenFilter = {
      $or: [{ hidden: false }, { hidden: { $exists: false } }],
    };
    const orFilters: QueryServicesRequestFilters[] = [hiddenFilter];

    if (staffMemberIds) {
      filter.staffMemberIds = staffMemberIds;
    }

    const serviceIdsFilter = {
      id: {
        $in: serviceIds,
      },
    };
    const categoryIdsFilter = {
      'category.id': {
        $in: categoryIds,
      },
    };
    if (serviceIds && categoryIds) {
      orFilters.push({
        $or: [serviceIdsFilter, categoryIdsFilter],
      });
    } else if (serviceIds) {
      filter = {
        ...filter,
        ...serviceIdsFilter,
      };
    } else if (categoryIds) {
      filter = {
        ...filter,
        ...categoryIdsFilter,
      };
    }

    if (locationIds) {
      const includeNonBusinessLocations = locationIds.includes(
        ReservedLocationIds.OTHER_LOCATIONS,
      );
      const businessLocationIds = locationIds.filter(
        (locationId) => locationId !== ReservedLocationIds.OTHER_LOCATIONS,
      );

      const otherLocationsFilter = {
        'locations.type': [LocationType.CUSTOM, LocationType.CUSTOMER],
      };
      const businessLocationFilter = {
        'locations.business.id': businessLocationIds,
      };

      if (includeNonBusinessLocations && businessLocationIds.length > 0) {
        orFilters.push({ $or: [businessLocationFilter, otherLocationsFilter] });
      } else if (includeNonBusinessLocations) {
        filter = { ...filter, ...otherLocationsFilter };
      } else {
        filter = { ...filter, ...businessLocationFilter };
      }
    }

    if (orFilters.length > 1) {
      filter.$and = orFilters;
    } else {
      const [f] = orFilters;
      filter.$or = (f as any).$or;
    }

    return this.withCache(`queryServices-${JSON.stringify(filters)}`, () =>
      this.flowAPI.httpClient.request(
        queryServices({
          query: {
            sort,
            paging,
            filter,
          },
        }),
      ),
    );
  }

  getBusinessInfo() {
    return this.withCache('getBusinessInfo', () =>
      this.catalogServer
        .BusinessCatalog()({ Authorization: this.authorization })
        .get({ suppressNotFoundError: false }),
    );
  }

  async notifyOwnerNonPremiumEnrollmentAttempt({
    service,
  }: {
    service: Service;
  }) {
    return this.notificationsServer
      .NotificationsSettings()({ authorization: this.authorization })
      .missedBooking({ serviceType: service.type });
  }

  notifyOwnerNonPricingPlanEnrollmentAttempt(data: object) {
    return this.flowAPI.httpClient.post(
      `${BOOKINGS_FES_BASE_DOMAIN}/pricing-plans/invalidSetup`,
      data,
      {
        headers: {
          'Content-Type': 'application/json',
          [REVISION_HEADER_NAME]:
            this.flowAPI.controllerConfig.wixCodeApi.site.revision,
          [XSRF_HEADER_NAME]:
            this.flowAPI.controllerConfig.platformAPIs.getCsrfToken(),
        },
      },
    );
  }
}
