import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { Resources, ViewMode } from '../../types/types';
import { WidgetData } from '../../../legacy/types';
import { createWidgetViewModel } from '../../viewModel/viewModel';
import { getCourseAvailability } from '../../actions/courseAvailability/courseAvailability';
import { filterWidgetData } from '../../utils/filters/filter-widget-data';
import { ActiveFeatures } from '@wix/bookings-uou-types';

export const updateSettings = async ({
  filteredResources,
  userData,
  dangerousPublicDataOverride,
  dangerousStylesOverride,
  presetId,
  scale,
  viewMode,
  flowAPI,
  newData,
  shouldWorkWithAppSettings,
}: {
  filteredResources: Resources;
  userData: WidgetData;
  presetId: string;
  flowAPI: ControllerFlowAPI;
  dangerousStylesOverride: Function;
  dangerousPublicDataOverride: Function;
  scale: number;
  viewMode: ViewMode;
  newData: any;
  shouldWorkWithAppSettings: boolean;
}) => {
  const { controllerConfig } = flowAPI;
  const { setProps } = controllerConfig;
  const isEditor = true;

  const publicData = dangerousPublicDataOverride(
    mergePresetIdToPublicData(newData.publicData, presetId),
  );

  const styleParams = dangerousStylesOverride(
    mergePresetIdToStyleParams(newData.style.styleParams, presetId),
  );

  if (userData.offerings && userData.offerings.length) {
    filteredResources = filterWidgetData(userData, flowAPI, presetId, isEditor);
  }
  const widgetViewModel = await createWidgetViewModel({
    scale,
    businessInfo: userData.config.businessInfo,
    filteredResources,
    flowAPI,
    viewMode,
    shouldWorkWithAppSettings,
    activeFeatures: {} as ActiveFeatures,
  });

  widgetViewModel.coursesAvailability = await getCourseAvailability(
    flowAPI,
    filteredResources,
  );

  setProps({
    widgetViewModel,
    ...styleParams,
    ...publicData,
  });
};

export const mergePresetIdToStyleParams = (
  stylesParams: any,
  presetId: string,
) => {
  return {
    ...stylesParams,
    numbers: { ...stylesParams.numbers, presetId },
  };
};

export const mergePresetIdToPublicData = (
  publicData: { APP: { [p: string]: any }; COMPONENT: { [p: string]: any } },
  presetId: string,
) => {
  const COMPONENT = {
    ...publicData.COMPONENT,
    presetId,
  };
  return { APP: publicData.APP, COMPONENT };
};
