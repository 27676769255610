import {
  isDemoMode,
  isTemplateMode,
} from '@wix/bookings-catalog-calendar-viewer-utils';
import { isFeatureEnabled } from '@wix/bookings-config/dist/src/active-features/feature-enabler';
import {
  CatalogServiceDto,
  ServiceType,
  OfferedAsType,
  ActiveFeatures,
} from '@wix/bookings-uou-types';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

export interface BlockNavigationReason {
  premiumError: boolean;
  pricingPlanError: boolean;
  isServiceConnectedToPricingPlans: boolean;
  isPricingPlanInstalled: boolean;
  siteNotEcomError: boolean;
  multiServiceAppointment: boolean;
}

export function isLiveSite(flowAPI: ControllerFlowAPI): boolean {
  return !(
    flowAPI.environment.isPreview ||
    isDemoMode({
      wixCodeApi: flowAPI.controllerConfig.wixCodeApi,
      appParams: flowAPI.controllerConfig.appParams,
    }) ||
    isTemplateMode({
      wixCodeApi: flowAPI.controllerConfig.wixCodeApi,
      appParams: flowAPI.controllerConfig.appParams,
    })
  );
}

export function shouldBlockNavigation({
  flowAPI,
  service,
  activeFeatures,
  isPricingPlanInstalled,
  businessInfo,
}: {
  flowAPI: ControllerFlowAPI;
  service: CatalogServiceDto;
  activeFeatures: ActiveFeatures;
  isPricingPlanInstalled: boolean;
  businessInfo: any;
}): BlockNavigationReason | undefined {
  const couldBePremium = couldBePremiumNavigate(service, activeFeatures);
  const couldBePricingPlan = couldBePricingPlanNavigate(
    service,
    isPricingPlanInstalled,
  );

  const multiServiceAppointment = shouldOpenServicesPreferencesModal({
    flowAPI,
    service,
    isMultiServicesAppointmentsEnable:
      !!businessInfo.isMultiServicesAppointmentsEnable,
  });

  return couldBePremium &&
    couldBePricingPlan.canBook &&
    !multiServiceAppointment
    ? undefined
    : {
        premiumError: !couldBePremium,
        pricingPlanError: !couldBePricingPlan.canBook,
        isServiceConnectedToPricingPlans:
          couldBePricingPlan.isServiceConnectedToPricingPlans,
        isPricingPlanInstalled,
        siteNotEcomError: false,
        multiServiceAppointment,
      };
}

function shouldOpenServicesPreferencesModal({
  flowAPI,
  service,
  isMultiServicesAppointmentsEnable,
}: {
  flowAPI: ControllerFlowAPI;
  service: CatalogServiceDto;
  isMultiServicesAppointmentsEnable: boolean;
}): boolean {
  return (
    flowAPI.experiments.enabled('specs.bookings.multiServiceAppointment') &&
    isMultiServicesAppointmentsEnable &&
    !service.dummy &&
    service.type === ServiceType.INDIVIDUAL
  );
}

function couldBePremiumNavigate(
  service: CatalogServiceDto,
  activeFeatures: ActiveFeatures,
): boolean {
  return (
    service.type === ServiceType.COURSE ||
    isFeatureEnabled(activeFeatures, service.type)
  );
}

function couldBePricingPlanNavigate(
  service: CatalogServiceDto,
  isPricingPlanInstalled: boolean,
): {
  canBook: boolean;
  isServiceConnectedToPricingPlans: boolean;
} {
  const isServiceConnectedToPricingPlans =
    isServiceConnectedToPricingPlan(service);

  const serviceOfferedAsPricingPlan = isServiceOfferedAsPricingPlan(
    service,
    isPricingPlanInstalled,
  );
  return {
    canBook:
      !serviceOfferedAsPricingPlan ||
      (isServiceConnectedToPricingPlans && isPricingPlanInstalled),
    isServiceConnectedToPricingPlans,
  };
}

const isServiceConnectedToPricingPlan = (service: CatalogServiceDto) => {
  return !!service?.pricingPlanInfo?.pricingPlans?.length;
};

const isServiceOfferedAsPricingPlan = (
  offeringViewModel: CatalogServiceDto,
  isPricingPlanInstalled: boolean,
) =>
  getOfferedAs(offeringViewModel, isPricingPlanInstalled).includes(
    OfferedAsType.PRICING_PLAN,
  );

const getOfferedAs = (
  service: CatalogServiceDto,
  isPricingPlanInstalled: boolean,
): OfferedAsType[] => {
  if (
    service.offeredAs.includes(OfferedAsType.ONE_TIME) &&
    service.offeredAs.includes(OfferedAsType.PRICING_PLAN)
  ) {
    if (service.pricingPlanInfo.pricingPlans.length === 0) {
      return [OfferedAsType.ONE_TIME];
    }
    if (!isPricingPlanInstalled) {
      return [OfferedAsType.ONE_TIME];
    }
  }
  return service.offeredAs;
};
