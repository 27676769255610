import {
  ActiveFeatures,
  CatalogServiceDto,
  ServiceType,
} from '@wix/bookings-uou-types';
import { ReferralInfo } from '../../utils/bi/consts';
import {
  BookingValidations,
  getNotifyPricingPlanRequest,
} from '../../utils/validation/booking-validations';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { BookingsApiDeprecated } from '../../api/BookingsApiDeprecated';
import { openServicesPreferencesModalAction } from '../openServicesPreferencesModal/openServicesPreferencesModal';
import { NavigateToNextPageAction } from '../navigateToNextPage/navigateToNextPage';
import { NavigationTarget } from '../navigateToNextPage/consts';
import { bookingsCantBookGroupsMessage } from '@wix/bi-logger-wixboost-ugc/v2';
import { OpenPremiumDialogAction } from '../openPremiumDialog/openPremiumDialog';
import { WidgetConfig } from '../../../legacy/types';
import { ServiceIntent } from '../../consts';
import { Service } from '../../types/types';

export type OnBookFlowStartAttemptAction = ({
  service,
  referralInfo,
}: {
  service: CatalogServiceDto;
  referralInfo: ReferralInfo;
}) => Promise<void>;

export const createOnBookFlowStartAttemptAction = ({
  flowAPI,
  bookingsApi,
  config,
  openServicesPreferencesModal,
  navigateToNextPage,
  openPremiumDialog,
}: {
  flowAPI: ControllerFlowAPI;
  bookingsApi: BookingsApiDeprecated;
  config: WidgetConfig;
  openServicesPreferencesModal: openServicesPreferencesModalAction;
  openPremiumDialog: OpenPremiumDialogAction;
  navigateToNextPage: NavigateToNextPageAction;
}): OnBookFlowStartAttemptAction => {
  return async ({
    service,
    referralInfo,
  }: {
    service: Service;
    referralInfo: ReferralInfo;
  }) => {
    try {
      if (
        flowAPI.experiments.enabled('specs.bookings.preCalculateShouldNavigate')
      ) {
        if (service.blockNavigationReason) {
          if (service.blockNavigationReason.multiServiceAppointment) {
            await openServicesPreferencesModal({ service });
          } else {
            if (service.blockNavigationReason.premiumError) {
              bookingsApi.notifyOwnerNonPremiumEnrollmentAttempt({
                service,
              });
            } else if (service.blockNavigationReason.pricingPlanError) {
              bookingsApi.notifyOwnerNonPricingPlanEnrollmentAttempt(
                getNotifyPricingPlanRequest(
                  service,
                  service.blockNavigationReason,
                ),
              );
            }
            flowAPI.bi?.report(
              bookingsCantBookGroupsMessage({
                referralInfo: ReferralInfo.WIDGET,
                failReason: service.blockNavigationReason?.siteNotEcomError
                  ? 'non ecom site'
                  : undefined,
              }),
            );

            await openPremiumDialog();
          }
        } else {
          await navigateToNextPage({
            service,
            referralInfo,
            target: NavigationTarget.CALENDAR_PAGE,
          });
        }
      } else {
        const bookingsValidations = new BookingValidations(
          bookingsApi,
          flowAPI,
        );
        // TODO: refactor bookingsValidation and replace it here
        const { canBook, reason } = await bookingsValidations.shouldNavigate(
          service,
          JSON.parse(config.activeFeatures) as ActiveFeatures,
          ServiceIntent.BOOK_OFFERING,
        );

        if (canBook) {
          if (
            flowAPI.experiments.enabled(
              'specs.bookings.multiServiceAppointment',
            ) &&
            !!config.businessInfo?.isMultiServicesAppointmentsEnable &&
            !service.dummy &&
            service.type === ServiceType.INDIVIDUAL
          ) {
            await openServicesPreferencesModal({ service });
          } else {
            await navigateToNextPage({
              service,
              referralInfo,
              target: NavigationTarget.CALENDAR_PAGE,
            });
          }
        } else {
          if (reason) {
            try {
              if (reason.premiumError) {
                await bookingsApi.notifyOwnerNonPremiumEnrollmentAttempt({
                  service,
                });
              } else if (reason.pricingPlanError) {
                await bookingsApi.notifyOwnerNonPricingPlanEnrollmentAttempt(
                  getNotifyPricingPlanRequest(service, reason),
                );
              }
            } catch (error) {
              console.error(error);
            }
          }

          flowAPI.bi?.report(
            bookingsCantBookGroupsMessage({
              referralInfo: ReferralInfo.WIDGET,
              failReason: reason?.siteNotEcomError
                ? 'non ecom site'
                : undefined,
            }),
          );

          await openPremiumDialog();
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
};
