import { ControllerParams, CreateControllerFn } from '@wix/yoshi-flow-editor';
import { WixOOISDKAdapter } from '@wix/bookings-adapter-ooi-wix-sdk';
import { bookingsWidgetPageLoaded } from '@wix/bi-logger-wixboost-ugc/v2';
import { BookingsApiDeprecated } from '../../api/BookingsApiDeprecated';
import { REQUESTED_STAFF_DEEP_LINK_ORIGIN } from '../../consts';
import { getFilteredResources } from '../../utils/filters/services-filter';
import { SettingsKeys, WidgetData } from '../../../legacy/types';
import { getPlatformBiLoggerDefaultsConfig } from '@wix/bookings-adapters-reporting/dist/src/bi-logger/platform-logger/platform-bookings-bi-defaults';
import {
  getAppSettingsClient,
  getUserSettings,
  updatePublicData,
} from '../../../legacy/appSettings/getAppSettings';
import {
  mergeAppSettingsToSettingsParams,
  mergeAppSettingsToStyleParams,
  ServiceListSettings,
} from '../../../legacy/appSettings/appSettings';
import { WidgetNamePhase1 } from '../../utils/bi/consts';
import {
  createWidgetViewModel,
  WidgetViewModel,
} from '../../viewModel/viewModel';
import { createWidgetActions, WidgetActions } from '../../actions/actions';
import { MigrationStatus, Resources, ViewMode } from '../../types/types';
import { shouldShowDummyContent } from '../../utils/dummyContent/dummyContent';
import { createDummyServicesDto } from '../../utils/dummyContent/dummyServicesDto/dummyServicesDto';
import { createDummyCategoriesDto } from '../../utils/dummyContent/dummyCategoriesDto/dummyCategoriesDto';
import { getPresetId, onLocationURLChange } from './controllerPrePageReady';
import { mergeOpacityToColor } from '../../../legacy/utils';
import { getCourseAvailabilityFromCache } from '../../actions/courseAvailability/courseAvailability';
import {
  isAnywhereFlow,
  mapPublicDataOverridesToPublicData,
} from '../../utils/anywhere/anywhere';
import {
  navigateToHeadlessIfNeeded,
  NavigationType,
} from '@wix/wix-to-headless-redirect-client';
import {
  mergePresetIdToPublicData,
  mergePresetIdToStyleParams,
  updateSettings,
} from './updateSettings';
import { filterWidgetData } from '../../utils/filters/filter-widget-data';
import SettingsParams from './settingsParams';
import { ITEM_TYPES } from '@wix/advanced-seo-utils/api';
import { mapServiceToServiceV2 } from '../../utils/serviceDetails/serviceDetails';
import {
  getScale,
  getUrlQueryParamValue,
  isRunningInIframe,
  BookingsQueryParams,
} from '@wix/bookings-catalog-calendar-viewer-utils';
import { BookingsAPI } from '../../api/BookingsApi';

export const createControllerFactory = (viewMode: ViewMode) => {
  const createController: CreateControllerFn = async ({
    flowAPI,
    dangerousPublicDataOverride,
    dangerousStylesOverride,
  }: ControllerParams) => {
    const {
      controllerConfig,
      environment: { isSSR, isEditor, isEditorX, isADI, isPreview },
      bi,
      experiments,
    } = flowAPI;
    const { config, wixCodeApi, platformAPIs, appParams, compId, setProps } =
      controllerConfig;
    let userData: WidgetData;
    let filteredResources: Resources;
    let widgetViewModel: WidgetViewModel;
    let scale: number;
    let currentUserAppSettings: ServiceListSettings,
      currentUserStylesParam: any;
    let publicData: any,
      stylesProp: any,
      shouldWorkWithAppSettings: boolean,
      userSettings: any;

    let presetId = getPresetId(config, isEditorX);

    let migrationStatus = flowAPI.settings.get(SettingsParams.migrationStatus);
    const wixSdkAdapter: WixOOISDKAdapter = new WixOOISDKAdapter(
      wixCodeApi,
      platformAPIs,
      appParams,
      compId,
      experiments,
    );

    const networkCache = new Map<string, any>();

    const biLoggerDefaultConfigurations = getPlatformBiLoggerDefaultsConfig(
      wixSdkAdapter,
      WidgetNamePhase1,
    );
    bi?.updateDefaults(biLoggerDefaultConfigurations);

    onLocationURLChange(wixCodeApi, () => pageReady());

    // When moving to app reflow, this should move to the App Reflow router (using getHeadlessUrl instead of navigateToHeadlessIfNeeded)
    const { navigatedToHeadless } = navigateToHeadlessIfNeeded({
      navParams: {
        logicalName: NavigationType.BOOKINGS_SERVICE_LIST,
      },
      location: wixCodeApi.location,
    });
    if (navigatedToHeadless) {
      // stop rendering
      return {
        pageReady: async () => {},
      };
    }

    const pageReady = async () => {
      const isMigrateServiceListToServiceV2Enabled = experiments.enabled(
        'specs.bookings.migrateServiceListToServiceV2',
      );
      const { config } = controllerConfig;
      const appSettingsClient = getAppSettingsClient(flowAPI);

      shouldWorkWithAppSettings = !!config.externalId;

      if (isMigrateServiceListToServiceV2Enabled && shouldWorkWithAppSettings) {
        userSettings = await getUserSettings(
          appSettingsClient,
          presetId,
          userData,
        );
      }

      if (isMigrateServiceListToServiceV2Enabled) {
        const newBookingsAPI = new BookingsAPI({
          appSettings: userSettings,
          flowAPI,
          shouldWorkWithAppSettings,
        });

        await Promise.all([
          newBookingsAPI.queryServices(),
          newBookingsAPI.getBusinessInfo(),
        ]);
      }

      const bookingsApi = new BookingsApiDeprecated({
        flowAPI,
        shouldWorkWithAppSettings,
        networkCache,
      });
      userData = await bookingsApi.getWidgetData();

      scale = await getScale();

      const businessInfo = userData.config.businessInfo;

      const isAnywhereFlowInd = await isAnywhereFlow(
        wixCodeApi,
        experiments,
        isPreview,
      );

      if (shouldWorkWithAppSettings) {
        if (!isMigrateServiceListToServiceV2Enabled) {
          userSettings = await getUserSettings(
            appSettingsClient,
            presetId,
            userData,
          );
        }
        const userStylesColorsWithOpacity = {};
        (
          Object.keys(config.style.styleParams.colors || {}) as SettingsKeys[]
        ).forEach((colorKey) => {
          // @ts-expect-error
          userStylesColorsWithOpacity[colorKey] = {
            ...config.style.styleParams!.colors![colorKey],
            value: userSettings[colorKey]
              ? mergeOpacityToColor(
                  userSettings[colorKey].value,
                  config.style.styleParams!.colors![colorKey]
                    .value as any as string,
                )
              : config.style.styleParams!.colors![colorKey].value,
          };
        });

        const publicDataOverrideURL = isAnywhereFlowInd
          ? getUrlQueryParamValue(
              wixCodeApi,
              BookingsQueryParams.PUBLIC_DATA_OVERRIDES,
            )
          : undefined;

        userSettings = {
          ...userSettings,
          ...(isAnywhereFlowInd ? {} : config.style.styleParams.fonts),
          ...userStylesColorsWithOpacity,
        };
        currentUserAppSettings = userSettings;
        currentUserStylesParam = config.style.styleParams;

        stylesProp = dangerousStylesOverride(
          mergeAppSettingsToStyleParams(
            userSettings,
            {
              booleans: {},
              numbers: {},
              googleFontsCssUrl: '',
            },
            presetId,
          ) as any,
        );

        const publicDataOverrides = isAnywhereFlowInd
          ? mapPublicDataOverridesToPublicData(
              experiments,
              publicDataOverrideURL,
              businessInfo?.name,
            )
          : undefined;

        publicData = dangerousPublicDataOverride(
          mergeAppSettingsToSettingsParams(
            userSettings,
            config.publicData,
            presetId,
            publicDataOverrides,
          ),
        );

        filteredResources = getFilteredResources(userData, userSettings);
      } else {
        stylesProp = dangerousStylesOverride(
          mergePresetIdToStyleParams(config.style.styleParams, presetId),
        );

        publicData = dangerousPublicDataOverride(
          mergePresetIdToPublicData(config.publicData, presetId),
        );

        filteredResources = filterWidgetData(
          userData,
          flowAPI,
          presetId,
          isEditor,
        );
      }

      if (
        shouldShowDummyContent({
          services: filteredResources.offerings,
          flowAPI,
        })
      ) {
        filteredResources.offerings = createDummyServicesDto(flowAPI, presetId);
        filteredResources.categories = createDummyCategoriesDto(
          flowAPI,
          presetId,
        );
      }

      widgetViewModel = await createWidgetViewModel({
        scale,
        businessInfo,
        filteredResources,
        flowAPI,
        viewMode,
        isAnywhereFlow: isAnywhereFlowInd,
        shouldWorkWithAppSettings,
        activeFeatures: JSON.parse(userData.config.activeFeatures),
      });

      const widgetActions: WidgetActions = createWidgetActions({
        widgetViewModel,
        filteredResources,
        wixSdkAdapter,
        bookingsApi,
        flowAPI,
        config: userData.config,
        setProps,
      });

      if (!isSSR && !isEditor) {
        const origin =
          getUrlQueryParamValue(wixCodeApi, BookingsQueryParams.STAFF) ||
          getUrlQueryParamValue(wixCodeApi, BookingsQueryParams.RESOURCE)
            ? REQUESTED_STAFF_DEEP_LINK_ORIGIN
            : undefined;

        bi?.report(
          bookingsWidgetPageLoaded({
            numOfServices: widgetViewModel.services.length,
            isExplorePlans:
              widgetViewModel.bodyViewModel
                .atLeastOneServiceHasExplorePlansLink,
            origin,
          }),
        );
      }

      if (shouldWorkWithAppSettings && isEditor && isRunningInIframe()) {
        appSettingsClient.onChange(
          async (newUserSettings: ServiceListSettings) => {
            currentUserAppSettings = newUserSettings;
            updatePublicData({
              filteredResources,
              newUserSettings,
              userData,
              presetId,
              flowAPI,
              dangerousStylesOverride,
              dangerousPublicDataOverride,
              scale,
              viewMode,
              newUserStylesSettings: currentUserStylesParam,
              shouldWorkWithAppSettings,
            });
          },
        );
      }

      widgetViewModel.coursesAvailability =
        getCourseAvailabilityFromCache(flowAPI);

      setProps({
        ...stylesProp,
        ...publicData,
        widgetViewModel: { ...widgetViewModel },
        widgetActions,
        fitToContentHeight: true,
      });

      wixCodeApi.seo.renderSEOTags({
        itemType: ITEM_TYPES.SERVICES_COMPONENT,
        itemData: {
          services: filteredResources.offerings.map((offering) =>
            mapServiceToServiceV2({ service: offering }),
          ),
        },
      });
    };
    return {
      pageReady,
      updateConfig(_$w, data) {
        shouldWorkWithAppSettings = !!data.externalId;
        if (
          experiments.enabled(
            'specs.bookings.serviceListRunPageReadyOnSettingsUpdate',
          )
        ) {
          if (shouldWorkWithAppSettings) {
            // Should have been relevant for ADI Editor OOI only when changing the design,
            // for other editors it's not relevant since all data is on app settings, and we have updateAppSettings function.
            // When we tried to wrap it isADI & !isRunningInIframe the component was rendered with defaults settings when we opened the settings panel.
            // WA - save the current most updated app settings data and call generic function to calculate public data/ styles param and widget view model
            presetId = isADI ? data.publicData.COMPONENT.presetId : presetId;
            currentUserStylesParam = data.style.styleParams;
            updatePublicData({
              filteredResources,
              newUserSettings: currentUserAppSettings,
              userData,
              presetId,
              flowAPI,
              dangerousStylesOverride,
              dangerousPublicDataOverride,
              scale,
              viewMode,
              newUserStylesSettings: currentUserStylesParam,
              shouldWorkWithAppSettings,
            });
          } else {
            pageReady();
          }
        } else {
          const updatedPublicData = data.publicData.COMPONENT || {};
          const isMigrateChanged =
            migrationStatus !== updatedPublicData.migrationStatus;
          if (isMigrateChanged) {
            migrationStatus = MigrationStatus.MIGRATED;
            pageReady();
          }
          if (shouldWorkWithAppSettings) {
            // Should have been relevant for ADI Editor OOI only when changing the design,
            // for other editors it's not relevant since all data is on app settings, and we have updateAppSettings function.
            // When we tried to wrap it isADI & !isRunningInIframe the component was rendered with defaults settings when we opened the settings panel.
            // WA - save the current most updated app settings data and call generic function to calculate public data/ styles param and widget view model
            presetId = isADI ? data.publicData.COMPONENT.presetId : presetId;
            currentUserStylesParam = data.style.styleParams;
            updatePublicData({
              filteredResources,
              newUserSettings: currentUserAppSettings,
              userData,
              presetId,
              flowAPI,
              dangerousStylesOverride,
              dangerousPublicDataOverride,
              scale,
              viewMode,
              newUserStylesSettings: currentUserStylesParam,
              shouldWorkWithAppSettings,
            });
          } else {
            updateSettings({
              filteredResources,
              userData,
              presetId,
              flowAPI,
              dangerousStylesOverride,
              dangerousPublicDataOverride,
              scale,
              viewMode,
              newData: data,
              shouldWorkWithAppSettings,
            });
          }
        }
      },
      updateAppSettings: (_event: any, updates: { [key: string]: any }) => {
        if (shouldWorkWithAppSettings) {
          // Relevant for editor OOI, changes on app settings, include texts/colors/fonts changes - covers all
          const { payload }: { payload: ServiceListSettings } = updates as any;
          if (!isRunningInIframe()) {
            currentUserAppSettings = payload;
            updatePublicData({
              filteredResources,
              newUserSettings: currentUserAppSettings,
              userData,
              presetId,
              flowAPI,
              dangerousStylesOverride,
              dangerousPublicDataOverride,
              scale,
              viewMode,
              newUserStylesSettings: currentUserStylesParam,
              shouldWorkWithAppSettings,
            });
          }
        }
      },
    };
  };

  return createController;
};

export default createControllerFactory(ViewMode.PAGE);
